import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BG from "../../assets/2015/11/MAZD0294-Mazda-Foundation-Website-V4_03.jpg"
import PrivacyPolicy from "../../assets/2016/02/Mazda-Foundation-Privacy-Policy-February-2016.pdf"

const ObtainingFunding = () => (
  <Layout page="funding">
    <SEO title="Obtaining Funding" />
    <div
      id="obtaining-funding"
      style={{
        background: `url(${BG}) repeat`,
      }}
    >
      <div id="title" className="green-bg vertical-padding-smaller center">
        <div className="container">
          <div className="horizontal-padding">
            <h1>OBTAINING FUNDING</h1>
          </div>
        </div>
      </div>
      <div className="vertical-padding-small">
        <div className="container">
          <div id="about" className="horizontal-padding">
            <p>
              Organisations applying for a grant must complete and lodge a Project Notification form. Details must include a précis of the project, how the project relates to the priority area, the amount of funding sought and how it would be used, and the expected outcome of the project. Such notification may be lodged electronically or by hard copy to the Mazda Foundation (at the address on the home page of this website).
              <br /><br />
              The <Link to="/about/board-of-directors">independent Board of Directors</Link> will consider each application and identify projects for which further detail will be sought. An agreed contribution will be made to the approved organisation(s) and the successful applicants notified.
              <br /><br />
              If lodging a Project Notification form, please note that:
              <ul>
                <li>
                  the project must fall within the Foundation’s nominated priority area;
                </li>
                <li>
                  the organisation must be an eligible recipient under the Income Tax Assessment Act;
                </li>
                <li>
                  the project must be consistent with the broader aims and objectives of the Foundation (published on this website); and all areas of the attached form must be completed.
                </li>
                <li>
                  The Foundation will acknowledge receipt of the Project Notification form, but this should not be seen as in any way indicating that the request for consideration will progress further.
                </li>
              </ul>
              Any Project Notification received by the Foundation will be considered at the Foundation’s Board meetings held in April and October. As such Project Notifications must be submitted for notification by 31 March and 30 September. The Foundation’s Board meets approximately four times per year, and accordingly a period of some months may elapse between lodgement of a Project Notification and it being selected for funding.
              <br /><br />
              Once the Foundation selects a project for funding, it may seek a detailed submission from the applicant and/or seek to confer with the applicant.
              <br /><br />
              The Foundation periodically identifies a priority area of focus. The Foundation currently has two priority areas of focus, being:
            </p>
          </div>
          <div className="grey-bg">
            <div className="horizontal-padding">
              <p style={{ margin: 0 }}><br/></p>
              <h3>Foundation Priorities</h3>
              <p>Community based organisations with projects supporting primary producers facing hardship.</p>
              <p>Programs addressing literacy and numeracy issues amongst primary school aged children, especially remedial programs for children from disadvantaged areas.</p>
              <Link
                className="button"
                to="/obtaining-funding/funding-request-primary-producer">
                Application Form
              </Link>
              <a
                className="button"
                href={PrivacyPolicy}
                download>
                Privacy Policy
              </a>
              <p style={{ margin: 0 }}><br/></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ObtainingFunding
